.form-select {
  &__control {
    background-color: var(--kt-input-solid-bg) !important;
    border-color: var(--kt-input-solid-bg) !important;
    min-height: 44px !important;
    border-radius: 0.65rem !important;

    &--menu-is-open {
      box-shadow: 0 0 0 1px var(--bs-primary) !important;
    }
  }

  &__single-value {
    color: var(--kt-input-solid-color) !important;
  }

  &-creatable {
    &__control {
      background-color: var(--kt-input-solid-bg) !important;
      border-color: var(--kt-input-solid-bg) !important;
      min-height: 44px !important;
      border-radius: 0.65rem !important;

      &--menu-is-open {
        box-shadow: 0 0 0 1px var(--bs-primary) !important;
      }
    }

    &__single-value {
      color: var(--kt-input-solid-color) !important;
    }

    &__option {
      &:last-child {
        background: var(--kt-success-active);
        color: var(--kt-white);
      }
    }

    &--transparent {
      .form-select-creatable {
        &__control {
          background-color: transparent !important;
          border-color: transparent !important;

          &:hover {
            border-color: var(--kt-input-solid-bg) !important;
          }
        }

        &__indicator-separator {
          display: none;
        }
      }
    }
  }
}
