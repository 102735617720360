#kt_header_search {
  &.show {
    &:before {
      width: 100%;
      height: 100%;
      content: '';
      position: absolute;
      left: 0;
      top: 0;
    }
  }
}